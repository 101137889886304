/* eslint-disable */
import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavLink, Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import { getSites } from '../api/UserAPI';
import {
  updateAttributes, updateAudience, updateMfaConfigured, updateRememberDevice,
  updatePhoneState,
  updateSelectedRoomId,
  updateSelectedRoomName,
  updateSelectedSiteId,
  updateSelectedSiteName,
  updateSitesList,
  updateUserId,
  updateCurrentUser,
  updateDevicesOrder
} from '../redux/userInfo';
import DashboardTab from './DashboardTab';
import {
  getSiteInfo, updateAutomations, updateDevices,
  updateUnassignedKits, updateAssignedKits, updateRooms, assignSensorCount,
  seperateArchived, getSiteOwnerId, updateUsersInfo, updateTheme 
} from './Update';
import firstKeyLogo from '../assets/firstkey-logo.svg';
import progressLogo from '../assets/progress-logo.png';
import stonecrestLogo from '../assets/stonecrestLogo.png';
import rogersBusiness from '../assets/RogersBusiness.png';
import rightChevron from '../assets/right-chevron.svg';
import swidgetLogo from '../assets/swidget-logo.png';
import silent from '../assets/silent.svg';
import DisplayDeviceContent from './DisplayDeviceContent';
import Dropdown from './Dropdown';

import './HomeTab.css';
import PropertyDetails from './PropertyDetails';
import AddPropertyPopup from './AddPropertyPopup';
import { customSortFunction } from './Update';

function HomeTab(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const logout = props.logoutHandler;
  const [isHome, setIsHome] = useState(true);
  const currentLocation = useRef();
  const currentSite = useRef();

  // user id, attributes, 2fa details
  const userId = location?.state?.userId;
  const attributes = location?.state?.attributes;
  const mfaConfigured = location?.state?.mfaConfigured;
  const rememberDevice = location?.state?.rememberDevice;
  const phoneState = location?.state?.phoneState;
  const currentUser = location?.state?.currentUser;
  const propertiesSortOptions = ['Active Alerts', 'Sensors Offline', 'Alphabetical'];
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [roomsNames, setRoomsNames] = useState([]);
  const [archivedFlagArray, setArchivedFlagArray] = useState([]);

  // add property
  const [state, setState] = useState({
    addPropertyPopup: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  // site info
  const dispatch = useDispatch();
  const storeUserId = useSelector((state) => state.userInfo.userId);
  const sites = useSelector((state) => state.userInfo.sites);
  const selectedSiteName = useSelector((state) => state.userInfo.selectedSiteName);
  const selectedSiteId = useSelector((state) => state.userInfo.selectedSiteId);
  const selectedRoomName = useSelector((state) => state.userInfo.selectedRoomName);
  const selectedRoomId = useSelector((state) => state.userInfo.selectedRoomId);
  const roomList = useSelector((state) => state.userInfo.roomList);
  const deviceList = useSelector((state) => state.userInfo.deviceList);
  const audience = useSelector((state) => state.userInfo.audience);
  const devicesOrder = useSelector((state) => state.userInfo.devicesOrder);

  useEffect(() => {
    if (audience)
      updateTheme(audience);
  },[audience])
  
  // list of archived property id's
  const archivedList = useSelector((state) => state.userInfo.archivedList);

  // search bar
  const [query, setQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(roomList.rooms);
  const [roomsWithOfflineSensors, setRoomsWithOfflineSensors] = useState([]);
  const [roomsSeperateArchived, setRoomsSeperateArchived] = useState([]);
  const [displayedRooms, setDisplayedRooms] = useState(filteredItems);
   const [sortedListBySensors, setSortedListBySensors] = useState([]); 
  const [sortedListAlphabetically, setSortedListAlphabetically] = useState([]);
  
  useEffect(() => {
    if (roomsWithOfflineSensors)
      setSortedListBySensors(roomsWithOfflineSensors.slice().sort((firstElement, secondElement) => secondElement.offlineSensorsCount - firstElement.offlineSensorsCount));
    if (filteredItems)
      setSortedListAlphabetically(filteredItems.slice().sort((firstElement, secondElement) => firstElement.name.localeCompare(secondElement.name)))
  }, [filteredItems, roomsWithOfflineSensors])

  useEffect(() => {
    if (roomList.rooms !== undefined & query === '') {
      setFilteredItems(roomList.rooms);
    }
  }, [selectedSiteId, roomList.rooms, query])

  useEffect(() => {
    if (query) setFilteredItems(roomList.rooms.filter((room) => room?.name.toLowerCase().includes(query.toLocaleLowerCase())));
  },[query])

  useEffect(() => {
    filteredItems
      ? setRoomsSeperateArchived(seperateArchived(filteredItems, archivedList))
      : setRoomsSeperateArchived([]);
    roomsSeperateArchived && deviceList.devices
      ? setRoomsWithOfflineSensors(assignSensorCount(deviceList.devices, roomsSeperateArchived))
      : setRoomsWithOfflineSensors([]);
  }, [query, roomList, filteredItems, deviceList]);

  useEffect(() => {
    const currentDevicesOrder = {...devicesOrder};
    if (deviceList?.devices && deviceList?.devices.length > 0) {
      deviceList.devices.forEach(device => {
        const installationStatus = device?.tags ? device?.tags.find(tag => tag.key === 'installationStatus') : '';
        if (installationStatus.value === 'uninstalled') currentDevicesOrder[device.deviceId] = 3;
        else {
          if ( device?.isConnected) {
            if (currentDevicesOrder[device.deviceId] === 1) currentDevicesOrder[device.deviceId] = 1; //if the device is in alert state keep its order value 1
            else currentDevicesOrder[device.deviceId] = 0; 
          } else currentDevicesOrder[device.deviceId] = 2
        }
      });
      dispatch(updateDevicesOrder(currentDevicesOrder ))
    }
  }, [deviceList, filteredItems]);


  useEffect(() => {
    const interval = setInterval(async () => {
      if (currentLocation.current === '/home') {
        await updateRooms(dispatch, currentSite.current);
        await updateDevices(dispatch, currentSite.current);
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);


  const init = useCallback(async () => {
    const response2 = await getSites();
    if (response2.success) {
      const sitesWithProperties = [];
      response2.sites.forEach((site) => {
        if (site.roomCount !== 0) {
            if (site.features.display === 'market')
              sitesWithProperties.push(site);
        }
      })
      let firstSite = sitesWithProperties.find( site => site.siteId === localStorage.getItem('selectedSiteId'));
      dispatch(updateSitesList(sitesWithProperties));
      // userId for login 
      // storeUserId for referesh
      if(sitesWithProperties) {
        if (!(firstSite && ((localStorage.getItem('idLinkedToSite') === storeUserId) || localStorage.getItem('idLinkedToSite') === userId ))) {
          firstSite = sitesWithProperties[0];
          localStorage.setItem('selectedSiteId', sitesWithProperties[0]?.siteId);
          localStorage.setItem('idLinkedToSite', storeUserId);
        }
        dispatch(updateAudience(firstSite?.ownedBy.audience));
        dispatch(updateSelectedSiteName(firstSite?.name));
        dispatch(updateSelectedSiteId(firstSite?.siteId));
        await updateRooms(dispatch, firstSite?.siteId);
        await updateDevices(dispatch, firstSite?.siteId);
        await updateAutomations(dispatch, firstSite?.siteId);
        await getSiteInfo(dispatch, firstSite?.siteId);
        await updateUnassignedKits(dispatch, firstSite?.siteId);
        await updateAssignedKits(dispatch, firstSite?.siteId);
        await getSiteOwnerId(dispatch, firstSite?.siteId);
        await updateUsersInfo(dispatch, firstSite?.siteId);
        setIsLoading(false);
      }
    }
  }, [dispatch]);
  // on first load:
  // - update theme based on audience
  // - set site to first one in list
  useEffect(() => {
    init();
  }, [init]);

  // when location changes - update isHome
  useEffect(() => {
    currentLocation.current = location.pathname;
    if (location.pathname === '/home') {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    currentSite.current = selectedSiteId;
  }, [selectedSiteId]);

  // run only once when component mounts
  useEffect(() => {
    if (!storeUserId) {
      dispatch(updateUserId(userId));
      dispatch(updateAttributes(attributes));
      dispatch(updateMfaConfigured(mfaConfigured));
      dispatch(updateRememberDevice(rememberDevice));
      dispatch(updatePhoneState(phoneState));
      dispatch(updateCurrentUser(currentUser));
      if (attributes === null) {
        console.log('No attributes found');
      }
    }
  }, [attributes, dispatch, mfaConfigured, rememberDevice, storeUserId, userId, phoneState, currentUser]);

  // when user clicks on city within dropdown - switch city
  const switchSite = async (chosenName, chosenId, audience) => {
    setQuery('');
    localStorage.setItem('selectedSiteId', chosenId);
    localStorage.setItem('idLinkedToSite', storeUserId);
    await getSiteInfo(dispatch,chosenId);
    dispatch(updateSelectedSiteName(chosenName));
    dispatch(updateSelectedSiteId(chosenId));
    dispatch(updateAudience(audience));
    if (!isHome) {
      navigate('/home');
    }
    await updateRooms(dispatch, chosenId);
    await updateDevices(dispatch, chosenId);
    await updateAutomations(dispatch, chosenId);
    await updateUnassignedKits(dispatch, chosenId);
    await updateAssignedKits(dispatch, chosenId);
    await getSiteOwnerId(dispatch, chosenId);
    await updateUsersInfo(dispatch, chosenId);
  };

  // when user clicks on room within dropdown - switch room
  const switchRoom = (chosenName, chosenId) => {
    dispatch(updateSelectedRoomName(chosenName));
    dispatch(updateSelectedRoomId(chosenId));
    navigate(`/home/market/${selectedSiteId}/property/${chosenId}/activitylog`);
  };

  const addProperty = () => {
    setState({ addPropertyPopup: true });
  };

  // display nav links on left side of each page
  function DisplayNavLinks() {
    return (
      archivedList?.includes(selectedRoomId)
        ? (
          <div className="nav-links archived">
            <NavLink to={`market/${selectedSiteId}/property/${selectedRoomId}/activitylog`}>Activity Log</NavLink>
          </div>
        )
        : (
          <div className="nav-links default">
            <NavLink to={`market/${selectedSiteId}/property/${selectedRoomId}/activitylog`}>Activity Log</NavLink>
            <NavLink to={`market/${selectedSiteId}/property/${selectedRoomId}/liveview`}>Live View</NavLink>
            <NavLink to={`market/${selectedSiteId}/property/${selectedRoomId}/automations`}>Automations</NavLink>
            <NavLink to={`market/${selectedSiteId}/property/${selectedRoomId}/support`}>Support</NavLink>
          </div>
        )
    );
  }

  const getLogo = (audience) => {
    if (audience === 'first_key') {
      return <img src={firstKeyLogo} alt="First Key Homes logo" className="logo" onClick={() => navigate('/home')} />;
    } if (audience === 'progress_residential') {
      return <img src={progressLogo} alt="Progress Residential logo" className="logo" onClick={() => navigate('/home')} />;
    } if (audience === 'stonecrest') {
      return <img src={stonecrestLogo} alt="stonecrest logo" className="logo" onClick={() => navigate('/home')} />;
    } if (audience === 'rogers_business') {
      return <img src={rogersBusiness} alt="rogers business logo" className="logo" onClick={() => navigate('/home')} />;
    }
    return <img src={swidgetLogo} alt="Swidget logo" className="logo" onClick={() => navigate('/home')} />;
  };

  const updateSortOption = (index) => {
    setSelectedOptionIndex(index);
  };

  useEffect(() => {
    if (selectedOptionIndex === 0) setDisplayedRooms(roomsSeperateArchived);
    else if (selectedOptionIndex === 1) {
      setDisplayedRooms(sortedListBySensors);
    } else setDisplayedRooms(sortedListAlphabetically);
  }, [selectedOptionIndex, filteredItems, roomsSeperateArchived]);

  const updateSiteOption = (index) => {
    switchSite(sites[index]?.name, sites[index]?.siteId, sites[index]?.ownedBy.audience);
  }

  const updateRoomOption = (index) => {
    switchRoom(roomsSeperateArchived[index]?.name, roomsSeperateArchived[index]?.roomId);
  };

  useEffect(() => {
    const archiveFlag = [];
      roomsSeperateArchived.length > 0
        ? setRoomsNames(roomsSeperateArchived.map((room) => {
          if (archivedList.includes(room.roomId)) archiveFlag.push('— Archived');
          else archiveFlag.push('');
          return room?.name;
        }))
        : setRoomsNames([]);

      setArchivedFlagArray(archiveFlag);
  }, [roomList.rooms, roomsSeperateArchived]);

  return (
    <>
      <Routes>
        <Route path="/" element={<DashboardTab logout={logout} />} />
        <Route path="market/:siteId/property/:roomId/liveview" element={<PropertyDetails chosenTab="address" />} />
        <Route path="market/:siteId/property/:roomId/automations" element={<PropertyDetails chosenTab="automation" />} />
        <Route path="market/:siteId/property/:roomId/activitylog" element={<PropertyDetails chosenTab="activityLog" />} />
        <Route path="market/:siteId/property/:roomId/support" element={<PropertyDetails chosenTab="support" />} />
      </Routes>

      <div id="home-container">
        <div className="left-container">

          {state.addPropertyPopup
            ? (
              <AddPropertyPopup
                selectedSiteName={selectedSiteName}
                selectedSiteId={selectedSiteId}
                switchSite={switchSite}
                setState={setState}
              />
            )
            : null}

          <header>
            {audience ? getLogo(audience) : getLogo('swidget_sfr') }
          </header>

          {/* SELECTED CITY */}
          {sites?.length > 0
            ? (
              <Dropdown
                updateOption={updateSiteOption}
                options={sites.map((site) => site.name)}
                options2={sites.map((site) => (site.roomCount > 1 ? `${site.roomCount} properties` : `${site.roomCount} property`))}
                fixedButtonLabel="+ Add new property"
                fixedButtonFuntion={addProperty}
                firstRowTag="h2"
                className="dropdown-btn large"
                className2="black"
                firstSelectedOptionIndex={sites.findIndex((site) => site.siteId === selectedSiteId)}
              />
            )
            :
            <div className='black-wrapper'>
                <h4>No site available</h4>
            </div> 
            }
          <div className="mb-05" />

          {/* SORT */}
          {isHome
            ? (
              <>
                <Dropdown label="Sort by: " options={propertiesSortOptions} updateOption={updateSortOption} />
                <input type="search" value={query} className="search-bar" placeholder="Search" onChange={(e) => setQuery(e.target.value)} />
              </>
            )
            : (
              <>
                <div className="mb-05">
                  {(selectedRoomName && roomsNames.length > 0)
                    ? (
                      <Dropdown
                        updateOption={updateRoomOption}
                        options={roomsNames}
                        options2={archivedFlagArray}
                        className="dropdown-btn archived"
                        className2="colored"
                        firstSelectedOptionIndex={roomsNames.indexOf(selectedRoomName)}
                      />
                    )
                    : (
                      <button disabled className="dropdown-btn">
                        <h3>No Properties</h3>
                        <img src={rightChevron} alt="down" className="chevron white down" />
                      </button>
                    )}
                </div>

                {DisplayNavLinks()}
              </>
            )}

          <div className="main-panel">
            <DisplayHomes
              isHome={isHome}
              filteredItems={displayedRooms}
              archivedList={archivedList}
              selectedSiteId={selectedSiteId}
              deviceList={deviceList}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>

    </>
  );
}

export default HomeTab;

function DisplayHomes(props) {
  const dispatch = useDispatch();
  const switchRoom = (id, name) => {
    dispatch(updateSelectedRoomName(name));
    dispatch(updateSelectedRoomId(id));
  };
  if (props.isHome) {
    return (
      props.filteredItems?.length
        ? props.filteredItems?.map((room) => (
          <div key={room.roomId} className="home-wrapper">
            {props.archivedList?.includes(room.roomId)
              ? (
                <>
                  <h4 className="mb-05">
                    <NavLink to={`market/${props.selectedSiteId}/property/${room.roomId}/activitylog`} className="nostyle">
                      {room.name}
                    </NavLink>
                  </h4>
                  <div className="no-devices-wrapper"><h5 className="black">Property Archived</h5></div>
                </>
              )
              : (
                <>
                  <h4 className="mb-05 property-name">
                    <NavLink to={`market/${props.selectedSiteId}/property/${room.roomId}/activitylog`} className="nostyle" onClickCapture={() => switchRoom(room.roomId, room.name)}>{room.name}</NavLink>
                    {room.settings.alerts.isSilent && <img id="silent-icon" src={silent} alt="silent"/>}
                  </h4>
                  <DisplayDevices roomId={room.roomId} deviceList={props.deviceList} isLoading />
                </>
              )}
          </div>
        ))
        : null
    );
  }
  return null;
}

// display video cameras & motion sensors
function DisplayDevices(props) {
  const roomId = props?.roomId;
  const devicesOrder = useSelector((state) => state.userInfo.devicesOrder);
  // list of devices in room
  const dList = props.deviceList.devices?.filter((device) => device.roomId === roomId);
  if (dList?.length) dList.sort((firstElement, secondElement) => customSortFunction(devicesOrder[firstElement.deviceId], devicesOrder[secondElement.deviceId], 'asc'));

  if (dList?.length) {
    return (
      <div className="tile-wrapper">
        {dList?.map((device) => (
          <DisplayDeviceContent key={device.deviceId} device={device} />
        ))}
      </div>
    );
  } if (props.isLoading) {
    return (
      <div className="no-devices-wrapper empty">
        <span className="loader dark" />
      </div>
    );
  }

  return <div className="no-devices-wrapper"><h5 className="black">Property Archived</h5></div>;
}
